<template>
  <div>
    <ca-header
      heading="News"
      :loading="loading"
    />
    <div class="container-fluid px-4 pt-4">
      <div
        v-if="!loading"
        class="row"
      >
        <div class="col-12 col-xl-8 col-xxl-6">
          <template v-if="news.length === 0">
            <div class="d-flex justify-content-center align-items-center mt-5">
              <div class="text-center">
                <i class="far fa-surprise fa-3x mb-3" />
                <div>Es gibt leider noch keine Newsbeiträge</div>
              </div>
            </div>
          </template>
          <template v-else>
            <news-article
              v-for="newsArticle of news"
              :key="newsArticle._id"
              :news="newsArticle"
            />
            <button
              v-if="total > skip"
              class="btn btn-primary"
              :disabled="findMorePending"
              @click="findMoreNews"
            >
              Mehr laden <i
                v-if="findMorePending"
                class="fa fa-circle-notch fa-spin"
              />
            </button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CaHeader from '@/components/Layout/Header'
import NewsArticle from '@/components/NewsArticle'
export default {
  name: 'NewsOverview',
  components: {
    NewsArticle,
    CaHeader
  },
  data () {
    return {
      fullText: false,
      loading: false,
      error: null,
      news: null,
      savePending: false,
      saveError: null,
      skip: 0,
      total: 0,
      findMorePending: false,
      findMoreError: null
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: 'fetchData'
    }
  },
  methods: {
    async fetchData () {
      try {
        this.loading = true
        this.news = await this.findNews()
        this.skip = this.news.length
      } catch (error) {
        console.error(error)
        this.error = error
      } finally {
        this.loading = false
      }
    },
    async findNews () {
      const newsRequest = await this.$store.dispatch('news/find', {
        query: {
          $skip: this.skip,
          $sort: { publishedAt: -1 }
        }
      })
      this.total = newsRequest.total
      const news = newsRequest.data
      return news
    },
    async findMoreNews () {
      try {
        this.findMorePending = true
        const moreNews = await this.findNews()
        this.skip += moreNews.length
        this.news.push(...moreNews)
      } catch (error) {
        console.error(error)
        this.findMoreError = error
      } finally {
        this.findMorePending = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  .news-article{
    p {
      margin-bottom: 0
    }
  }

</style>
