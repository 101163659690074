<!-- eslint-disable vue/no-v-html -->
<template>
  <ca-card
    :img-small="true"
    class="news-article mb-3"
    :img-source="news.coverImage ? news.coverImage.downloadUrl : undefined"
  >
    <template #body>
      <div class="row align-items-center">
        <div class="col">
          <h3>
            {{ news.title }}
          </h3>
        </div>
        <div class="col-auto">
          <span v-if="user.role === 10">
            <router-link :to="{name: 'NewsEdit', params: {newsId: news._id}}">
              <i class="fas fa-pen" />
            </router-link>
          </span>
        </div>
      </div>
      <p :class="[{'font-weight-bold': fullText, 'mb-4': fullText}]">
        {{ news.teaser }}
      </p>
      <p
        v-if="fullText"
        class="news-content"
        v-html="news.content"
      />
    </template>
    <template #footer>
      <div class="row justify-content-between">
        <div class="col-auto">
          <p class="m-0 text-muted">
            {{ news.publishedAt | fromNow }}
          </p>
        </div>
        <div class="col-auto">
          <button
            class="btn btn-link p-0"
            @click="fullText = !fullText"
          >
            <i class="far fa-ellipsis-h" />
            {{ fullText ? 'Weniger' : 'Mehr' }} anzeigen
          </button>
        </div>
      </div>
    </template>
  </ca-card>
</template>

<script>
import CaCard from '@/components/Card'
import fromNow from '../filters/fromNow.js'
export default {
  name: 'NewsArticle',
  components: {
    CaCard
  },
  filters: {
    fromNow
  },
  props: {
    news: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    fullText: false
  }),
  computed: {
    user () {
      return this.$store.getters['auth/user'].user
    }
  }
}
</script>

<style lang="scss" scoped>
  .news-content {
    white-space: pre-line;
  }
</style>
